import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/modal';
var setYear = function () {
    var currentYear = document.querySelector("#current-year");
    currentYear.innerHTML = new Date().getFullYear().toString();
};
var setBanners = function () {
    var bannerOne = document.getElementById("banner-1");
    var bannerTwo = document.getElementById("banner-2");
    var random = (Math.random() < 0.5);
    bannerOne.hidden = random;
    bannerTwo.hidden = !random;
};
function ready(fn) {
    if (document.readyState != 'loading') {
        fn();
    }
    else {
        document.addEventListener('DOMContentLoaded', function (e) {
            fn();
        });
    }
}
ready(function () {
    setYear();
    setBanners();
});
